import dayjs, { Dayjs } from 'dayjs';
import { getUTCDaysBetween } from './dateUtils';

export const getNumberOfStops = (itinerary: {
  homebound: { legs: unknown[] }[];
  outbound: { legs: unknown[] }[];
}) => {
  const { homebound, outbound } = itinerary;

  const outboudLegs = outbound.flatMap((route) => route.legs);
  const homeboundLegs = homebound.flatMap((route) => route.legs);
  const outboundStops = outboudLegs.length > 0 ? outboudLegs.length - 1 : 0;
  const homeboundStops =
    homeboundLegs.length > 0 ? homeboundLegs.length - 1 : 0;

  return outboundStops + homeboundStops;
};

interface PartialLeg {
  arrival: Dayjs;
  departure: Dayjs;
  duration: number;
}

interface PartialRoute {
  arrival: Dayjs;
  departure: Dayjs;
  duration: number;
  legs: PartialLeg[];
}

const toDayJsIfNeeded = (date: Dayjs | string | undefined) =>
  typeof date === 'string' ? dayjs(date) : date;

export const getTotalDuration = (routes: PartialRoute[]): number => {
  return routes
    .flatMap((route) => route.legs)
    .reduce((acc, leg, idx, legs) => {
      const legDuration = leg.duration || 0;

      // Seems to be some mismatch in these types between the Gateway and the Client
      // They DayJs objects arrive to the client as strings but still typed as DayJs objects
      const departure = toDayJsIfNeeded(legs[idx + 1]?.departure);

      const stopDuration = departure?.diff(leg.arrival, 'seconds') || 0;

      return acc + legDuration + stopDuration;
    }, 0);
};

export const boundHasOvernightStay = (routes: PartialRoute[]): boolean => {
  const allLegs = routes.flatMap((route) => route.legs);

  for (let i = 1; i < allLegs.length; i++) {
    const previousLeg = allLegs[i - 1];
    const currentLeg = allLegs[i];

    // Seems to be some mismatch in these types between the Gateway and the Client
    // They DayJs objects arrive to the client as strings but still typed as DayJs objects
    if (
      previousLeg &&
      currentLeg &&
      getUTCDaysBetween(
        toDayJsIfNeeded(previousLeg.arrival) as Dayjs,
        toDayJsIfNeeded(currentLeg.departure) as Dayjs,
      ) > 0
    ) {
      return true;
    }
  }

  return false;
};

// Returns true if offer contains an overnight stopover
export const isOvernightStay = (bounds: PartialRoute[][]): boolean =>
  bounds.some((bound) => bound.length > 1 && boundHasOvernightStay(bound));

// Returns true if offer contains an overnight (red eye) flight
export const isOvernightFlight = (routes: PartialRoute[]): boolean => {
  const allLegs = routes.flatMap((route) => route.legs);

  return allLegs.some((leg) => {
    return !toDayJsIfNeeded(leg.departure)?.isSame(
      toDayJsIfNeeded(leg.arrival),
      'date',
    );
  });
};
